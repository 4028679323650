/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "ring-width",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ring-width",
    "aria-label": "ring width permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ring Width"), "\n", React.createElement(_components.p, null, "Utilities for creating outline rings with box-shadows."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "ring={ringWidth}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "--x-ring-width: {ringWidth}; box-shadow: var(--x-ring-inset) 0 0 0 var(--x-ring-width, 3px) var(--x-ring-color);"))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "ringInset")), React.createElement(_components.td, null, React.createElement(_components.code, null, "--x-ring-inset: 1;"))))), "\n", React.createElement(_components.h2, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "ring={ringWidth}"), " utilities to apply solid box-shadow of a specific thickness to an element. Rings are a semi-transparent blue color by default, similar to the default focus ring style in many systems."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=blue"
  }, "<>\n  <template preview>\n    <x.div\n      display=\"grid\"\n      gridTemplateColumns={{ _: 1, sm: 4 }}\n      gap={6}\n      justifyItems=\"center\"\n    >\n      <x.button\n        tabindex=\"-1\"\n        w={24}\n        outline={{ focus: 'none' }}\n        fontSize=\"sm\"\n        py={3}\n        borderRadius=\"md\"\n        fontWeight=\"semibold\"\n        color=\"white\"\n        bg=\"blue-500\"\n        ring={0}\n      >\n        {`ring={0}`}\n      </x.button>\n      <x.button\n        tabindex=\"-1\"\n        w={24}\n        outline={{ focus: 'none' }}\n        fontSize=\"sm\"\n        py={3}\n        borderRadius=\"md\"\n        fontWeight=\"semibold\"\n        color=\"white\"\n        bg=\"blue-500\"\n        ring={2}\n      >\n        {`ring={2}`}\n      </x.button>\n      <x.button\n        tabindex=\"-1\"\n        w={24}\n        outline={{ focus: 'none' }}\n        fontSize=\"sm\"\n        py={3}\n        borderRadius=\"md\"\n        fontWeight=\"semibold\"\n        color=\"white\"\n        bg=\"blue-500\"\n        ring\n      >\n        ring\n      </x.button>\n      <x.button\n        tabindex=\"-1\"\n        w={24}\n        outline={{ focus: 'none' }}\n        fontSize=\"sm\"\n        py={3}\n        borderRadius=\"md\"\n        fontWeight=\"semibold\"\n        color=\"white\"\n        bg=\"blue-500\"\n        ring={4}\n      >\n        {`ring={4}`}\n      </x.button>\n    </x.div>\n  </template>\n  <x.button ring={0}>ring-0</x.button>\n  <x.button ring={2}>ring-2</x.button>\n  <x.button ring>ring</x.button>\n  <x.button ring={4}>ring-4</x.button>\n</>\n")), "\n", React.createElement(_components.p, null, "Ring utilities compose gracefully with regular ", React.createElement(_components.code, null, "boxShadow={shadow}"), " utility and can be combined on the same element."), "\n", React.createElement(_components.h2, {
    id: "focus-rings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#focus-rings",
    "aria-label": "focus rings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Focus rings"), "\n", React.createElement(_components.p, null, "Add focus rings using ", React.createElement(_components.code, null, "ring={{ focus: ringWidth }}"), " utility."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=emerald"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" justifyContent=\"center\">\n      <x.button\n        w={32}\n        py={2}\n        borderRadius=\"md\"\n        fontWeight=\"semibold\"\n        color=\"white\"\n        bg=\"emerald-500\"\n        outline={{ focus: 'none' }}\n        ring={{ focus: 4 }}\n        ringColor={{ focus: 'emerald-500-a50' }}\n      >\n        Button\n      </x.button>\n    </x.div>\n  </template>\n  <x.button ring={{ focus: 4 }} ringColor={{ focus: 'emerald-500-a50' }}>\n    Button\n  </x.button>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "inset-rings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#inset-rings",
    "aria-label": "inset rings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Inset rings"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "ringInset"), " utility to force a ring to render on the inside of an element instead of the outside. This can be useful for elements at the edge of the screen where part of the ring wouldn't be visible."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=pink"
  }, "<>\n  <template preview>\n    <x.div display=\"flex\" spaceX={6} justifyContent=\"center\" p={8}>\n      <x.button\n        tabindex=\"-1\"\n        outline={{ focus: 'none' }}\n        w={32}\n        py={2}\n        borderRadius=\"md\"\n        fontWeight=\"semibold\"\n        color=\"white\"\n        bg=\"pink-500\"\n        ring={4}\n        ringColor=\"pink-300\"\n      >\n        Default\n      </x.button>\n      <x.button\n        tabindex=\"-1\"\n        outline={{ focus: 'none' }}\n        w={32}\n        py={2}\n        borderRadius=\"md\"\n        fontWeight=\"semibold\"\n        color=\"white\"\n        bg=\"pink-500\"\n        ring={4}\n        ringColor=\"pink-300\"\n        ringInset\n      >\n        Inset\n      </x.button>\n    </x.div>\n  </template>\n  <x.button ring={4} ringColor=\"pink-300\">\n    Default\n  </x.button>\n  <x.button ring={4} ringColor=\"pink-300\" ringInset>\n    Inset\n  </x.button>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control outline rings at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "ring={{ md: 2 }}"), " to an element would apply the ", React.createElement(_components.code, null, "ring={2}"), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div ring={{ md: 2 }}>{/* ... */}</x.div>\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
